:root {
  --omcare-dark-purple: #250e62;
  --omcare-light-purple: #865e9c;
  --omcare-teal: #6bcaba;
}

@font-face {
  font-family: 'ChaletLondonSixty';
  src: url('./fonts/Chalet-LondonSixty.otf');
}

html * {
  font-family: ChaletLondonSixty;
}

.container {
  margin: 50px auto 20px auto;
}

.omcare-navbar {
  background-color: var(--omcare-dark-purple);
}

.omcare-dashboard {
  font-size: 40px;
  color: var(--omcare-dark-purple);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-label {
  font-weight: bold;
}

#customer_detail_header {
  text-align: center;
  margin-top: 50px;
}

.teal-button,
.teal-button:visited {
  background-color: var(--omcare-teal);
  border-color: var(--omcare-teal);
}

.teal-button:hover {
  background-color: var(--omcare-teal);
  border-color: var(--omcare-teal);
  opacity: 0.8;
}

.teal-button:disabled,
.teal-button:active {
  background-color: var(--omcare-teal);
  border-color: var(--omcare-teal);
  opacity: 0.5;
}

.light_purple_button {
  background-color: var(--omcare-light-purple);
}

.modal_button_left {
  float: left;
  margin-right: 20px;
}

.button-header {
  padding-top: 10px;
  padding-left: 10px;
}

.omcare-button-dark {
  background-color: var(--omcare-dark-purple);
}

.omcare-button-light {
  background-color: var(--omcare-light-purple);
}

.omcare-button-teal {
  background-color: var(--omcare-teal);
}

.omcare-text-default-color {
  color: var(--omcare-dark-purple);
}

.Toastify__toast--info {
  background: #865e9c !important;
}
.Toastify__toast--success {
  background: #6bcaba !important;
  color: #2a2a30 !important;
}
.Toastify__close-button--success {
  color: #2a2a30 !important;
}
.Toastify__toast--error {
  background: #db5543 !important;
}
